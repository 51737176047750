import * as _ from "lodash";
import { FormGroup } from "@angular/forms";
import { FormControlIntermundial } from "./form-control-intermundial.class";
import { iFieldForm } from "@app/interface/field-form.interfaces";

export class FormBuilderMethodsClass {

    // Objeto base de formularios
    baseForms: any;

    // Modelo de datos del cual se pueden extraer datos default u opciones para campos tipo select
    model: any;

    /**
     * Retorna el formulario generado a partir de la estructura
     * @param tipoFormulario Tipo de formulario a obtener desde baseForms
     * @param returnNewFormGroup Bandera que indica si se va a crear y retornar un nuevo FormGroup
     */
    _getForm(tipoFormulario: string, returnNewFormGroup?: boolean, extraSettings?: any): FormGroup {
        // Obtener estructura segÃºn el tipo de formulario
        const structure: Array<any> = this.baseForms[tipoFormulario];

        // Si es un formArray se crea un nuevo FormGroup
        // de lo contrario se toma el this como el FormGroup
        let formGroup: any;
        if (returnNewFormGroup) {
            formGroup = new FormGroup({});
        } else {
            formGroup = this;
        }

        // Iterar sobre cada field para reescribir los validators con parÃ¡metros extra
        _.forEach(structure, (field: iFieldForm) => {
            // Agregar FormControlMamba personalizar al formGroup
            formGroup.addControl(field.key, new FormControlIntermundial(
                field.key,
                field.label,
                field.value,
                field.placeholder || "",
                field.options || [],
                {
                    ...field.settings,
                    ...extraSettings,
                    tipoFormulario: tipoFormulario
                },
                field.disabled || false,
                field.validators || []
            ));

        });

        return formGroup;
    }

}
