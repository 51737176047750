import * as _ from "lodash";
import { FormControl, ValidatorFn, AsyncValidatorFn } from "@angular/forms";

export class FormControlIntermundial extends FormControl {

    // Campos personalizados para FormControlMamba
    key: any;
    settings: any;
    label: string;
    options: Array<any>;
    placeholder: string;

    constructor(
        key?: any,
        label?: string,
        formState?: any,
        placeholder?: string,
        options?: any | null,
        settings?: any | null,
        disabled?: boolean | null,
        validator?: ValidatorFn | ValidatorFn[] | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(formState, validator, asyncValidator);

        this.key = key;
        this.label = label ? label : "";
        this.options = options;
        this.settings = settings;
        this.placeholder = placeholder ? placeholder : "";

        // Desactivar formControl si se enviÃ³ la bandera de disabled
        if (disabled) {
            this.disable();
        } else {
            this.enable();
        }

    }

    /**
     * Setear opciones para este FormControlMamba
     * @param id
     * @param nombre
     * @param numero_parametro
     * @param options
     */
    setOptions(id: any, nombre: any, numero_parametro: any, options: Array<any>) {
        this.options = []
        _.forEach(options, option => {
            // Setear opciones con este formato personalizado
            this.options.push({
                key: option.nombre,
                value: {
                    id: id,
                    nombre: nombre,
                    tipo: this.key,
                    numero_parametro: numero_parametro,
                    valores: [
                        {
                            id: option.id,
                            nombre: option.nombre,
                            desde: option.desde,
                            hasta: option.hasta
                        }
                    ]
                }
            });
        });
    }
}
