export function Mixin(baseCtors: Function[]) {
    return function (derivedCtor: Function) {
        baseCtors.forEach(baseCtor => {
            Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
                const descriptor = Object.getOwnPropertyDescriptor(baseCtor.prototype, name);
                if (name !== 'constructor') {
                  derivedCtor.prototype[name] = baseCtor.prototype[name];
                }
            });
        });
    };
}